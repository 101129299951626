import { lazy } from "react";

import { app, guest } from "~/helpers/routes";
import { paths } from "~/router/paths";

const HomePage = lazy(() => import("~/pages/home"));
const LoginPage = lazy(() => import("~/pages/login"));
const RegisterPage = lazy(() => import("~/pages/register"));
const ForgotPasswordPage = lazy(() => import("~/pages/forgot_password"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(HomePage),
  },
  {
    path: paths.app.login,
    element: guest(LoginPage),
  },
  {
    path: paths.app.register,
    element: guest(RegisterPage),
  },
  {
    path: paths.app.forgot,
    element: guest(ForgotPasswordPage),
  }
];
