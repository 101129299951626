import { navItems } from "~/constants/sidebar";
import Button from "~/spocketUI/components/Button";
import { SVG } from "~/spocketUI/components/SVG";
// @ts-ignore
import { ReactComponent as LogoSvg } from "~/assets/svg/logo.svg";
import { useMediaQuery } from "~/spocketUI/hooks/useMediaQuery";
import * as S from "./Sidebar.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { paths } from "~/router/paths";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StyledLink } from "~/spocketUI/components/Styled/Link";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import { SVGIcon } from "~/spocketUI/components/SVG/types";

const Brand = () => {
  return (
    <S.LinkContainer>
      <StyledLink to={paths.app.home}>
        <S.BrandContainer>
          <LogoSvg style={{ height: 40, width: "100%" }} />
        </S.BrandContainer>
      </StyledLink>
    </S.LinkContainer>
  );
};

interface MenuItemProps {
  path: string;
  openInNewTab?: boolean;
  icon?: SVGIcon;
  namePath: string;
}

const MenuItem = ({ path, openInNewTab, icon, namePath }: MenuItemProps) => {
  const { t } = useTranslation();

  return (
    <Fragment key={path}>
      <S.NavItem
        style={{ textDecoration: "none", width: "100%" }}
        to={path}
        key={path}
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noopener noreferrer" : undefined}
      >
        {({ isActive }: { isActive: boolean }) => (
          <Button
            variant={isActive ? "filled" : "default"}
            color={isActive ? "primary" : "secondary"}
            fontWeight={isActive ? 500 : 400}
            bgColor="white"
            radius={0.75}
            justifyContent="flex-start"
          >
            {icon && <SVG icon={icon} svgProp={{ width: 22 }} />}
            {t(namePath)}
          </Button>
        )}
      </S.NavItem>
    </Fragment>
  );
};

const Menu = () => {
  const settingsIndex = useMemo(
    () => navItems.findIndex(({ path }) => path === paths.settings.plans),
    [navItems]
  );

  const [itemsBeforeSettings, itemsAfterSettings] = useMemo(() => {
    return [
      navItems.slice(0, settingsIndex),
      navItems.slice(settingsIndex, navItems.length),
    ];
  }, []);

  return (
    <S.NavContainer>
      <FlexContainer
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <FlexContainer flexDirection="column" width="100%">
          {itemsBeforeSettings.map((navItem) => (
            <MenuItem key={navItem.path} {...navItem} />
          ))}
        </FlexContainer>
        <FlexContainer flexDirection="column" width="100%">
          {itemsAfterSettings.map((navItem) => (
            <MenuItem key={navItem.path} {...navItem} />
          ))}
        </FlexContainer>
      </FlexContainer>
    </S.NavContainer>
  );
};

const DesktopSidebar = () => {
  return (
    <S.SidebarContainer>
      <Brand />
      <Menu />
    </S.SidebarContainer>
  );
};

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <S.SidebarContainer>
      <Brand />
      <S.MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
        <SVG icon={faBars} size="lg" />
      </S.MobileMenuButton>
      {isOpen && (
        <S.MobileMenu>
          <Menu />
        </S.MobileMenu>
      )}
    </S.SidebarContainer>
  );
};

const Sidebar = () => {
  const isTabletAndUp = useMediaQuery("tablet");
  if (isTabletAndUp) return <DesktopSidebar />;
  return <MobileSidebar />;
};

export default Sidebar;
