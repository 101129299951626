import { lazy } from "react";

import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const Orders = lazy(() => import("~/pages/orders"));

export const routes: Array<any> = [
  {
    path: paths.orders.index,
    element: app(Orders),
  },
];
