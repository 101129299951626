import React from "react";
import * as S from "./PageWrapper.style";

interface IProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<IProps> = ({ children = null }) => {
  return (
    <S.PageWrapper>
      <S.PageMaxWidth>
        <>{children}</>
      </S.PageMaxWidth>
    </S.PageWrapper>
  );
};
