import { lazy } from "react";

import { paths } from "~/router/paths";

const StoreLogin = lazy(() => import("~/pages/store-login"));

export const routes: Array<any> = [
  {
    path: `${paths.auth.store_login}:shop_token`,
    element: <StoreLogin />,
  },
];
