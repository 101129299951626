import styled from "styled-components";
import {
  getColor,
  getFadeInAnimation,
  getSize,
  responsive,
} from "~/spocketUI/helpers/style";
import { sidebarMobileHeight, sidebarWidth } from "./Sidebar.style";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${sidebarMobileHeight}px);
  max-height: calc(100vh - ${sidebarMobileHeight}px);
  overflow-y: auto;
  width: 100vw;
  left: 0;
  top: ${sidebarMobileHeight}px;
  position: fixed;
  padding: ${getSize(1.25)};
  background-color: ${getColor("background")};
  ${getFadeInAnimation(0.5)}

  ${responsive("tablet")} {
    min-height: 100vh;
    max-height: 100vh;
    padding: ${getSize(5.8)} ${getSize(9.2)};
    width: calc(100vw - ${sidebarWidth}px);
    top: 0;
    left: ${sidebarWidth}px;
  }
`;

export const PageMaxWidth = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;
