import React from "react";

import { paths } from "~/router/paths";
import { PageWrapper } from "~/components/layout/PageWrapper";
import RouteGuard from "~/components/layout/RouteGuard";

import { Navigate } from "react-router-dom";
import { redirectRoutes } from "~/router/redirects";
import Sidebar from "~/components/layout/Sidebar";

export const makeRedirects = () => {
  return redirectRoutes.map(({ path, to }) => ({
    path,
    element: <Navigate to={to} />,
  }));
};

export const app = (Component: React.FC<any>) => {
  // Auth Guard (must be logged in)
  return (
    <RouteGuard
      to={paths.app.login}
      render={
        <>
          <Sidebar />
          <PageWrapper>
            <Component />
          </PageWrapper>
        </>
      }
    />
  );
};

export const guest = (Component: React.FC<any>) => {
  // Login/Register Guard (if logged in, redirect to home)
  return <RouteGuard guest to={paths.app.login} render={<Component />} />;
};
