import { lazy } from "react";

import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const NFTMakerIntroduction = lazy(
  () => import("~/pages/nft_maker/Introduction")
);
const NFTMaker = lazy(() => import("~/pages/nft_maker"));
const MyNFTs = lazy(() => import("~/pages/my_nfts"));
const RedeemNFT = lazy(() => import("~/pages/redeem_nft"));

export const routes: Array<any> = [
  {
    path: paths.nft.maker,
    element: app(NFTMakerIntroduction),
  },
  {
    path: paths.nft.maker_create,
    element: app(NFTMaker),
  },
  {
    path: paths.nft.my_nfts,
    element: app(MyNFTs),
  },
  {
    path: paths.nft.redeem_nft,
    element: <RedeemNFT />,
  },
];
