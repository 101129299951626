import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { getColor, getSize, responsive } from "~/spocketUI/helpers/style";
import { Link } from "react-router-dom";

export const sidebarWidth = 230;
export const sidebarMobileHeight = 64;

export const SidebarContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getSize(1)} 0;
  width: 100vw;
  min-height: ${sidebarMobileHeight}px;
  background-color: ${getColor("backgroundSecondary")};
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;

  ${responsive("tablet")} {
    padding: ${getSize(2)} 0;
    width: ${sidebarWidth}px;
    height: 100vh;
    justify-content: flex-start;
  }
`;

export const NavContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: ${getSize(5.8)};
  padding: 0;
  height: 100%;

  ${responsive("tablet")} {
    padding: ${getSize(0.6)};
    width: 100%;
  }
`;

export const NavItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${getColor("text")};
  margin-bottom: ${getSize(0.5)};

  > button {
    width: 100%;
    text-align: center;
    font-size: 15px;

    svg {
      font-size: 20px;
    }

    ${responsive("tablet")} {
      text-align: left;
    }
  }
`;

export const MobileMenu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  height: calc(${sidebarMobileHeight}px - ${getSize(2)});

  ${responsive("tablet")} {
    width: 100%;
  }
`;

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${getSize(1)} ${getSize(2)};

  & > svg {
    margin-right: ${getSize(1)};
  }
`;

export const MobileMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(${sidebarMobileHeight}px - ${getSize(1)});
  width: calc(${sidebarMobileHeight}px - ${getSize(1)});
  position: absolute;
  right: ${getSize(1)};
  top: ${getSize(0.5)};
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
`;

export const SidebarFooter = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;

  ${responsive("tablet")} {
    position: absolute;
    padding: ${getSize(1)} ${getSize(2)};
  }

  > button {
    width: 100%;
    font-weight: 600;
    font-size: ${getSize(1.25)};

    svg {
      margin-right: ${getSize(0.5)};
    }
  }
`;

export const SidebarMenuSeparator = styled.div`
  width: calc(100% - ${getSize(3)});
  height: 1px;
  background-color: ${getColor("borderSecondary")};
  margin: ${getSize(1.5)} ${getSize(1.5)};
`;
