import { makeRedirects } from "~/helpers/routes";

import { routes as app } from "~/router/routes/app";
import { routes as auth } from "~/router/routes/auth";
import { routes as settings } from "~/router/routes/settings";
import { routes as nft } from "~/router/routes/nft";
import { routes as orders } from "~/router/routes/orders";
import { routes as checkout } from "~/router/routes/checkout";

export const routes = [
  ...app,
  ...auth,
  ...settings,
  ...nft,
  ...orders,
  ...checkout,
  ...makeRedirects(),
];
