import { Icon } from "@fortawesome/fontawesome-svg-core";
import {
  faHouseChimney,
  faCog,
  faCartShopping,
  faHexagonImage,
  faStore,
  faSquareInfo,
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";

export const navItems: Array<INavItem> = [
  {
    namePath: "nav.home",
    path: paths.app.home,
    icon: faHouseChimney as Icon,
  },
  {
    namePath: "nav.nft_maker",
    path: paths.nft.maker,
    icon: faCartShopping as Icon,
  },
  {
    namePath: "nav.my_nfts",
    path: paths.nft.my_nfts,
    icon: faHexagonImage as Icon,
  },
  {
    namePath: "order_collections.title",
    path: paths.orders.index,
    icon: faStore as Icon,
  },
  {
    namePath: "settings.title",
    path: paths.settings.plans,
    icon: faCog as Icon,
  },
  {
    namePath: "nav.help_center",
    path: "https://intercom.help/dnft-675adca52cdf/en/",
    openInNewTab: true,
    icon: faSquareInfo as Icon,
  },
];
