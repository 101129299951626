import { lazy } from "react";
import { app } from "~/helpers/routes";

import { paths } from "~/router/paths";

const Checkout = lazy(() => import("~/pages/checkout"));

export const routes: Array<any> = [
  {
    path: `${paths.checkout.index}`,
    element: app(Checkout),
  },
];
