import { IRootPaths } from "~/types/routing";

import { paths as auth } from "~/router/paths/auth";
import { paths as app } from "~/router/paths/app";
import { paths as settings } from "~/router/paths/settings";
import { paths as nft } from "~/router/paths/nft";
import { paths as orders } from "~/router/paths/orders";
import { paths as checkout } from "~/router/paths/checkout";

export const paths: IRootPaths = {
  auth,
  app,
  settings,
  nft,
  orders,
  checkout
};
